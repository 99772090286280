import { OfferFee } from './offer';
import { BaseModel } from './utils';

export const pickupIntervals = [
  'Tag',
  'Woche',
  'Monat',
  'Jahr',
  'Quartal',
] as const;

export const preferredContractDuration = [
  null, // 'Keine Angabe' represents an unspecified duration. For cleaner approach just using null value.
  'P1Y', // '1 Jahr' represents a duration of 1 year
  'P2Y', // '2 Jahre' represents a duration of 2 years
  'P3Y', // '3 Jahre' represents a duration of 3 years
] as const;

export const weightUnits = ['kg', 'Tonne', 'Liter', 'm³'] as const;
export const physicalAttributes = [
  'Fest',
  'Flüssig',
  'Gasförmig',
  'Staub/Pulverförmig',
  'Pastös/Breiig',
  'Schlammig',
  'Radioaktiv',
  'Stark erhitzt',
  'Stichfest',
] as const;
export const hpCriteria = [
  'HP-1 explosiv',
  'HP-2 brandfördernd',
  'HP-3 entzündbar',
  'HP-4 reizend',
  'HP-5 Zielorganotoxizität (STOT) Aspirationsgefahr',
  'HP-6 akute Toxizität',
  'HP-7 karzinogen',
  'HP-8 ätzend',
  'HP-9 infektiös',
  'HP-10 reproduktionstoxisch',
  'HP-11 mutagen',
  'HP-12 Freisetzung eines akut toxischen Gases',
  'HP-13 Sensibilisierung',
  'HP-14 ökotoxisch (ozonschichtschädigend und gewässerschädigend)',
  'HP-15 Abfall, der einer der o.g. Eigenschaften entwickeln kann, die der Ursprüngliche Abfall nicht unmittelbar aufweist',
  'nicht bekannt',
] as const;
export const loadingOptions = ['Stapler', 'Kran', 'Hubwagen'] as const;
export const palettes = [
  'Europalette',
  'Holzpalette',
  'Kunststoffpalette',
  'Pressholzpalette',
  'Sonstige',
] as const;
export const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const WEEKDAYS_NUMBER_VALUE = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 7,
};

export const INQUIRY_STATUSES = [
  'DRAFT',
  'OPEN',
  'SAMPLE_PENDING',
  'VERIFIED',
  'FINISHED',
  'RETRACTED',
  'TIMED_OUT',
  'FINISHED',
  'FINISHED_PARTLY',
  'OFFERS',
] as const;

export enum InquiryStatuses {
  'DRAFT' = 'DRAFT',
  'OPEN' = 'OPEN',
  'SAMPLE_PENDING' = 'SAMPLE_PENDING',
  'VERIFIED' = 'VERIFIED',
  'RETRACTED' = 'RETRACTED',
  'TIMED_OUT' = 'TIMED_OUT',
  'FINISHED' = 'FINISHED',
  'FINISHED_PARTLY' = 'FINISHED_PARTLY',
  'OFFERS' = 'OFFERS',
}

export type InquiryStatus = keyof typeof InquiryStatuses;
export type ProjectType =
  | 'ONCE'
  | 'YEARLY'
  | 'PROJECT'
  | 'FRAMEWORK'
  | 'framework';
export type Weekday = (typeof weekdays)[number];
export type PickupInterval = (typeof pickupIntervals)[number];
export type preferredContractDuration =
  (typeof preferredContractDuration)[number];
export type WeightUnit = (typeof weightUnits)[number];
export type HpCriterium = (typeof hpCriteria)[number];
export type PhysicalAttribute = (typeof physicalAttributes)[number];
export type LoadingOption = (typeof loadingOptions)[number];
export type Palette = (typeof palettes)[number];

export type Container = {
  type: string;
  group: string;
  customSize: string;
  customDescription: string;
  loadingOption: LoadingOption | null;
  palette: Palette | null;
  quantity: number;
  size: number;
  unit: WeightUnit;
  exchange: boolean;
  clean: boolean;
  empty: boolean;
  hoseLengthInM: number;
  entryRestrictions: string;
  internal: boolean;
  containerFee: OfferFee;
};

export type InquiryElement = {
  id?: string;
  state?: 'ACCEPTED';
  aut: string;
  ewc: string;
  sampleRequested: boolean;
  category: string;
  individualLabel: string;
  quantity: string;
  unit: WeightUnit;
  origin: string;
  physicalAttributes: PhysicalAttribute[];
  hpCriteria: HpCriterium[];
  customAttributes: string;
  containers: Container[];
  containersRequired: boolean;
  wasteImageUploads: string[];
  pickup: {
    quantityPerPickup: string;
    quantityPerPickupUnit: WeightUnit;
    earliestDate: string;
    latestDate: string;
    comment: string;
    frequency: number;
    interval: PickupInterval;
    weekdays: Weekday[];
    hasRegularPickups: boolean;
  };
};

export type Inquiry = Partial<BaseModel> & {
  status?: InquiryStatus;
  id?: string;
  companyId?: string;
  userId?: string;
  type: ProjectType;
  preferredContractDuration: preferredContractDuration; // IsoDate
  transportByProducer: boolean;
  locationId: string;
  excludedDisposers: string[];
  askCurrentDisposers: boolean;
  currentDisposers: string[];
  inquiryElements: InquiryElement[];
  quotationDeadline: string; // IsoDate
  provisionPercentage?: string;
};
